import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { I18n } from '@aws-amplify/core';
import { EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Row, Col, Button, DatePicker, Form, Input, InputRef, Modal, Select } from 'antd';

import { useEwaJdsD86Context } from '@/components/views/EwaJdsD86/context';
import { useApplicationContext } from '@/context/v1/Application/context';
import { AntdDropdownOption, TaskListProps } from './types';
import { CustomFormItem, EditButton } from './styles';
import { useStepKeysContext } from '../../context';
import { Title } from '@/components/Typography';
import { useForm } from 'antd/lib/form/Form';
import { BoardsType } from '@/types';
import { Tasks } from './Tasks';
import {
	useCreateCustomReportActionPlan,
	useUpdateCustomReportActionPlan,
	useDeleteCustomReportActionPlan,
	UpdateCustomReportActionPlanDTO,
	CustomReportActionPlanResponse,
	UpdateActionPlanTaskDTO,
	useGetUsers
} from '@/hooks';

const { useWatch } = Form;

interface ActionPlanModalProps {
	visible: boolean;
	stepKeyId?: string;
	onClose: () => void;
	subStepKeyId?: string;
	actionPlan?: CustomReportActionPlanResponse;
}

export function ActionPlanModal({
	visible,
	stepKeyId,
	actionPlan,
	subStepKeyId,
	onClose
}: Readonly<ActionPlanModalProps>) {
	const [form] = useForm();
	const { file_id } = useEwaJdsD86Context();
	const { formFieldName } = useStepKeysContext();
	const { organization, company } = useApplicationContext();

	const title = actionPlan?.title;

	const [editingTitle, setEditingTitle] = useState<boolean>(!title);
	const [tasks, setTasks] = useState<UpdateActionPlanTaskDTO[]>([]);
	const [actionPlanTitle, setActionPlanTitle] = useState<string | undefined>(title);

	const { isFetching: fetchingUsers, data: responsibleUsers } = useGetUsers({
		organization_id: organization.id,
		company_id: company.id
	});

	const { mutateAsync: createActionPlan, isLoading: creatingActionPlan } = useCreateCustomReportActionPlan();
	const { mutateAsync: updateActionPlan, isLoading: updatingActionPlan } = useUpdateCustomReportActionPlan();
	const { mutateAsync: deleteActionPlan, isLoading: deletingActionPlan } = useDeleteCustomReportActionPlan();

	const deadline = useWatch([...formFieldName, 'action_plans', 'deadline'], form) ?? actionPlan?.deadline;
	const description = useWatch([...formFieldName, 'action_plans', 'description'], form) ?? actionPlan?.description;

	useEffect(() => {
		if (actionPlan) {
			form.setFieldValue([...formFieldName, 'action_plans', 'deadline'], moment(actionPlan.deadline));
			form.setFieldValue([...formFieldName, 'action_plans', 'description'], actionPlan.description);
			form.setFieldValue(
				[...formFieldName, 'action_plans', 'responsible_user_id'],
				actionPlan.responsible_user_id
			);
		}
	}, [actionPlan]);

	async function handleDeleteActionPlan(): Promise<void> {
		Modal.confirm({
			title: I18n.get('Warning!'),
			okType: 'danger',
			okText: I18n.get('Yes'),
			cancelText: I18n.get('Cancel'),
			icon: <ExclamationCircleOutlined />,
			content: I18n.get('Do you want to delete this action plan?'),
			async onOk() {
				form.setFieldValue([...formFieldName, 'action_plans', 'title'], undefined);
				form.setFieldValue([...formFieldName, 'action_plans', 'deadline'], undefined);
				form.setFieldValue([...formFieldName, 'action_plans', 'description'], undefined);
				form.setFieldValue([...formFieldName, 'action_plans', 'responsible_user_id'], undefined);
				setActionPlanTitle(undefined);
				onClose();

				await deleteActionPlan({
					company_id: company?.id,
					action_plan_id: actionPlan?.id,
					organization_id: organization?.id
				});
			}
		});
	}

	const editInputRef = useCallback((node: InputRef): void => {
		if (node) {
			node.focus();
		}
	}, []);

	function onEditTitle(): void {
		setEditingTitle(true);
	}

	async function onFinishEditing(): Promise<void> {
		const title = form.getFieldValue([...formFieldName, 'action_plans', 'title']);
		if (title) {
			setActionPlanTitle(title);
			setEditingTitle(false);
		}
	}

	function handleTasksChange(tasks: TaskListProps[]): void {
		const body: UpdateActionPlanTaskDTO[] = tasks.map((task) => {
			const mappedTask: UpdateActionPlanTaskDTO = {
				type: task.type,
				is_completed: task.isCompleted,
				description: task.description
			};

			if (task.taskId) {
				mappedTask.id = task.taskId;
			}

			return mappedTask;
		});

		setTasks(body);
		form.resetFields(['task_title', 'condition']);
	}

	function mapResponsibleUsers(): AntdDropdownOption[] {
		return responsibleUsers.map(({ id, name }) => ({
			label: name,
			value: id
		}));
	}

	function createActionPlanMapper(values: any): UpdateCustomReportActionPlanDTO {
		return {
			organization_id: organization?.id,
			company_id: company?.id,
			action_plan_id: actionPlan?.id,
			tasks: tasks,
			action_plan: {
				title: actionPlanTitle,
				board: BoardsType.TO_DO,
				deadline: values.deadline,
				description: values.description,
				responsible_user_id: values.responsible_user_id
			}
		};
	}

	async function handleOnFinish(): Promise<void> {
		await form.validateFields();
		const values = form.getFieldValue([...formFieldName, 'action_plans']);
		const payload = createActionPlanMapper(values);

		let createdActionPlan;

		if (actionPlan?.id) {
			createdActionPlan = await updateActionPlan(payload);
		} else {
			createdActionPlan = await createActionPlan({
				...payload,
				file_id,
				custom_report_step_key_id: stepKeyId,
				custom_report_sub_step_key_id: subStepKeyId
			});
		}

		if (createdActionPlan) {
			onClose();
		}
	}

	return (
		<Modal open={visible} onCancel={onClose} width={750} footer={false} centered>
			<Form form={form} onFinish={handleOnFinish} layout="vertical">
				<Row justify="center">
					<Col xs={24}>
						<Title level={5}>Create action plan</Title>
					</Col>
					<Col xs={22}>
						<Row justify="center" gutter={[10, 0]} style={{ marginTop: '1rem' }}>
							<Col xs={24}>
								<Title style={{ marginBottom: '1rem' }} level={5}>
									{editingTitle ? (
										<CustomFormItem
											labelAlign="left"
											labelCol={{ xs: 24 }}
											label={I18n.get('Title')}
											name={[...formFieldName, 'action_plans', 'title']}
											rules={[
												{
													message: I18n.get('Enter action plan title'),
													required: true
												}
											]}
										>
											<Input
												ref={editInputRef}
												onBlur={onFinishEditing}
												style={{ fontSize: '1.4rem' }}
												onPressEnter={onFinishEditing}
												placeholder={I18n.get('Title')}
											/>
										</CustomFormItem>
									) : (
										<Row align="top">
											<Col xs="auto">
												<Title style={{ margin: '0' }} level={5}>
													{I18n.get(actionPlanTitle)}
												</Title>
											</Col>
											<Col xs={2}>
												<EditButton onClick={onEditTitle}>
													<EditOutlined />
												</EditButton>
											</Col>
										</Row>
									)}
								</Title>
							</Col>
							<Col xs={12}>
								<CustomFormItem
									style={{ paddingTop: '10px' }}
									label={I18n.get('Person responsible')}
									name={[...formFieldName, 'action_plans', 'responsible_user_id']}
									rules={[
										{
											message: I18n.get('Please enter a responsible'),
											required: true
										}
									]}
								>
									<Select
										allowClear
										loading={fetchingUsers}
										style={{ width: '100%' }}
										options={mapResponsibleUsers()}
										placeholder={I18n.get('Person responsible')}
										value={
											responsibleUsers.find((user) => user.id === actionPlan?.responsible_user_id)
												?.id
										}
									/>
								</CustomFormItem>
							</Col>
							<Col xs={12}>
								<CustomFormItem
									rules={[
										{
											required: true,
											message: I18n.get('Please enter a valid date')
										}
									]}
									name={[...formFieldName, 'action_plans', 'deadline']}
									style={{ paddingTop: '10px' }}
									label={I18n.get('Deadline')}
								>
									<DatePicker
										format="L"
										value={moment(deadline)}
										style={{ width: '100%' }}
										placeholder={I18n.get('Deadline')}
									/>
								</CustomFormItem>
							</Col>
							<Col span={24}>
								<CustomFormItem
									style={{ margin: '0' }}
									name={[...formFieldName, 'action_plans', 'description']}
									label={I18n.get(
										'Describe the situation found and the preventive measures already implemented (if exist)'
									)}
								>
									<Input.TextArea
										rows={2}
										showCount
										maxLength={500}
										value={description}
										style={{ height: '100%' }}
										autoSize={{ minRows: 5, maxRows: 5 }}
										placeholder={I18n.get('Enter a description for your action plan.')}
									/>
								</CustomFormItem>
							</Col>
						</Row>
					</Col>
					<Col xs={22}>
						<Row justify="center" gutter={[10, 0]} style={{ marginTop: '1rem' }}>
							<Col xs={24}>
								<Title level={5}>{I18n.get('Tasks')}</Title>
							</Col>
							<Tasks fetchedTasks={actionPlan?.action_plan_task} onTasksChange={handleTasksChange} />
						</Row>
					</Col>
					<Col xs={22}>
						<Row justify="center">
							{actionPlan?.id ? (
								<Button
									danger
									type="primary"
									style={{ marginTop: '1rem' }}
									onClick={handleDeleteActionPlan}
									loading={creatingActionPlan || updatingActionPlan || deletingActionPlan}
									disabled={creatingActionPlan || updatingActionPlan || deletingActionPlan}
								>
									{I18n.get('Delete')}
								</Button>
							) : (
								<Button
									onClick={onClose}
									style={{ marginTop: '1rem' }}
									loading={creatingActionPlan || updatingActionPlan || deletingActionPlan}
									disabled={creatingActionPlan || updatingActionPlan || deletingActionPlan}
								>
									{I18n.get('Cancel')}
								</Button>
							)}
							<Button
								type="primary"
								htmlType="submit"
								style={{ margin: '1rem 0 0 0.5rem' }}
								loading={creatingActionPlan || updatingActionPlan || deletingActionPlan}
								disabled={creatingActionPlan || updatingActionPlan || deletingActionPlan}
							>
								{I18n.get('Save')}
							</Button>
						</Row>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
}
