import React from 'react';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import { I18n } from '@aws-amplify/core';
import { LoadingOutlined } from '@ant-design/icons';

import { ReportContainer, SectionTitle, SectionContainer, FooterStyled } from './styles';
import { DividerCustom } from '@/components/ui/DividerCustom';
import { ButtonsCustom } from './ButtonsCustom';
import { Information } from './Information';
import { Comment } from './Comment';
import { Header } from './Header';

export function ReportTemplate({
	title,
	sector,
	sections,
	fileData,
	isLoading,
	component,
	reportData,
	workstation,
	isLoadingPDF,
	onDownloadPDF,
	pdfProps = {},
	onChangeComment,
	consolidated = false,
	onConsolidatePDF = {},
	hasCustomModal = false,
	hasConsolidated = false,
	hideWorkConditions = false,
	hideCharacteristics = false
}) {
	if (isLoading) {
		return (
			<Col span={24}>
				<LoadingOutlined />
			</Col>
		);
	}

	return (
		<Row justify="center" align="middle">
			<Col style={{ display: 'flex', flexDirection: 'column' }} span={24}>
				<ReportContainer span={24}>
					<Row>
						<Col span={24}>
							<Header title={title} />
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<SectionTitle>{I18n.get('Informations')}</SectionTitle>
						</Col>
						<Col span={24}>
							<Information file={fileData} reportData={reportData} workstation={workstation} />
						</Col>
					</Row>

					<DividerCustom />

					{!component &&
						sections.map(({ id, title, isSubSteps, component }, index) => (
							<React.Fragment key={id ?? index}>
								<Row>
									{title && (
										<Col span={24}>
											<SectionTitle $isSubSteps={isSubSteps}>{title}</SectionTitle>
										</Col>
									)}
									<Col span={24}>
										<SectionContainer $isSubSteps={isSubSteps}>{component}</SectionContainer>
									</Col>
								</Row>
								<DividerCustom />
							</React.Fragment>
						))}
					{component}
					<Row>
						<FooterStyled span={24}>
							<Row align="bottom" justify="space-between" gutter={[0, 20]}>
								<Col span={24}>
									<Comment
										onChangeComment={onChangeComment}
										disabled={consolidated || isLoadingPDF}
									/>
								</Col>
								<Col span={24}>
									<ButtonsCustom
										consolidated={consolidated}
										fileData={fileData}
										hasConsolidated={hasConsolidated}
										isLoadingPDF={isLoadingPDF}
										onConsolidatePDF={onConsolidatePDF}
										onDownloadPDF={onDownloadPDF}
										hasCustomModal={hasCustomModal}
										pdfProps={pdfProps}
									/>
								</Col>
							</Row>
						</FooterStyled>
					</Row>
				</ReportContainer>
			</Col>
		</Row>
	);
}

ReportTemplate.propTypes = {
	component: PropTypes.element
};
