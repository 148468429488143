import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '@/services/api';
import { message } from 'antd';
import type { AxiosError } from 'axios';
import { I18n } from '@aws-amplify/core';
import type { BaseContext, Response, ActionPlan, BoardsType } from '@/types';

export type UpdateActionPlanDTO = {
	title?: string;
	deadline?: Date;
	board?: BoardsType;
	description?: string;
	responsible_user_id?: string;
};

export type UpdateActionPlanTaskDTO = {
	id?: string;
	task_id?: string;
	description: string;
	type: string;
	is_completed?: boolean;
};

export type UpdateCustomReportActionPlanDTO = BaseContext & {
	action_plan_id?: string;
	action_plan: UpdateActionPlanDTO;
	tasks?: UpdateActionPlanTaskDTO[];
};

async function updateCustomReportActionPlan(payload: UpdateCustomReportActionPlanDTO): Promise<ActionPlan> {
	const url = `/action_plan/checklist/${payload.action_plan_id}`;
	const { data } = await Api.put<ActionPlan>(url, payload);
	return data;
}

export const useUpdateCustomReportActionPlan = () => {
	const queryClient = useQueryClient();
	return useMutation<ActionPlan, AxiosError<Response>, UpdateCustomReportActionPlanDTO>(
		(body) => updateCustomReportActionPlan(body),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['custom_report_action_plan']);
				message.success(I18n.get('Action plan updated successfully'));
			},
			onError: (err) => {
				message.error(I18n.get(err?.response?.data?.message ?? err?.message));
			}
		}
	);
};
