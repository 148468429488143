import React from 'react';
import { Row, Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { useUpdateStepKeyResults, useCreateStepKeyResults, useUpdateCustomReportResultScores } from '@/hooks';
import { StepKeysProvider } from '@/components/CustomReports/StepKeys/DefaultSelects/context';
import { CustomButton } from '@/components/ui/Buttons/CustomButton/styles';
import { useApplicationContext } from '@/context/v1/Application/context';
import { Paragraph, Title } from '@/components/Typography';
import { useEwaContext } from '../../../context';
import * as C from '@/components/CustomReports';
import { SubStepKeys } from './SubStepKeys';
import { PreviousCol } from '../../styles';
import type {
	UpdateOrCreateAdditionalItemResult,
	UpdateOrCreateSubStepKeyResult,
	StepKeyAdditionalItem,
	StepKeyResultValues,
	UpdateStepKeyResult,
	AdditionalItemsForm,
	SubStepKeysResult,
	AdditionalItem,
	StepKeysValues,
	StepKey
} from '@/hooks';

type StepKeyResultForUniqueAdditionalItem = {
	stepKeyId: string;
	values: StepKeysValues;
	additionalItem: AdditionalItemsForm;
	currentStepKey: StepKey | undefined;
	custom_report_step_key_additional_item: StepKeyAdditionalItem | undefined;
};

type StepKeyResultForMultipleAdditionalItems = {
	stepKeyId: string;
	values: StepKeysValues;
	localValues: AdditionalItem[];
	currentStepKey: StepKey | undefined;
	custom_report_step_key_additional_item: StepKeyAdditionalItem | undefined;
};

type StepKeyResultForSubStepKey = {
	stepKeyId: string;
	currentStepKey: StepKey;
	subStepKeyFormValues: SubStepKeysResult;
};

interface GenericStepProps {
	title: string;
	stepId: string;
	stepNumber: number;
	stepKeys?: StepKey[];
}

const { useFormInstance } = Form;

const REBA_STEP = 'kinebot';

export const GenericStep: React.FC<GenericStepProps> = ({ stepId, title, stepKeys, stepNumber }) => {
	const {
		ewa,
		file_id,
		currentStep,
		customReport,
		scrollToError,
		handleNextStep,
		handlePreviousStep,
		lastGenericStepNumber
	} = useEwaContext();
	const { company, organization } = useApplicationContext();

	const { getFieldValue, validateFields } = useFormInstance();

	const { mutateAsync: createStepKeys, isLoading: creatingStepKeys } = useCreateStepKeyResults();
	const { mutateAsync: updateStepKeys, isLoading: updatingStepKeys } = useUpdateStepKeyResults();
	const { mutateAsync: updateResultScore, isLoading: updatingScores } = useUpdateCustomReportResultScores();

	async function handleValidation() {
		try {
			await validateFields();
			const values: StepKeysValues = await getFieldValue([`${stepId}`]);
			const stepKeys = handleStepKeys(values);

			if (shouldUpdate()) {
				const updated = await updateStepKeys({
					step_id: stepId,
					company_id: company?.id,
					step_keys_results: stepKeys,
					organization_id: organization?.id,
					custom_report_id: customReport.id,
					custom_report_result_id: ewa.result_id
				});

				if (updated) {
					await updateResultScore({
						company_id: company?.id,
						organization_id: organization?.id,
						custom_report_result_id: ewa.result_id
					});
					handleNextStep();
					return;
				}

				return;
			}

			const created = await createStepKeys({
				step_id: stepId,
				company_id: company?.id,
				step_keys_results: stepKeys,
				organization_id: organization?.id,
				custom_report_id: customReport.id,
				custom_report_result_id: ewa.result_id
			});

			if (created) {
				await updateResultScore({
					company_id: company?.id,
					organization_id: organization?.id,
					custom_report_result_id: ewa.result_id
				});
				handleNextStep();
			}
		} catch (error) {
			scrollToError(error);
		}
	}

	function shouldUpdate(): boolean {
		return !!ewa.steps[currentStep].step_keys?.some(
			({ custom_report_step_key_result }) => !!custom_report_step_key_result?.id
		);
	}

	function handleStepKeys(values: StepKeysValues): UpdateStepKeyResult[] {
		return Object.keys(values).map((stepKeyId) => {
			let currentResult = values[stepKeyId];

			/* For some reason the action plan is added in the steps_keys_results */
			// @ts-ignore
			delete currentResult.action_plans;

			const currentStep = ewa.steps.find(({ id }) => stepId === id);
			const currentStepKey = currentStep?.step_keys?.find(({ id }) => stepKeyId === id);

			if (isEmptyStepKey(currentResult) && currentStepKey) {
				return mapEmptyStepKeyResult(currentStepKey);
			}

			if (hasSubStepKeyResult(currentResult) && currentStepKey) {
				const subStepKeyFormValues = currentResult;
				return mapStepKeyResultForSubStepKey({
					stepKeyId,
					currentStepKey,
					subStepKeyFormValues
				});
			}

			const additionalItem = currentResult.additional_items as AdditionalItemsForm;
			const localValues = additionalItem?.local_values;

			const custom_report_step_key_additional_item = mapStepKeyAdditionalItem(currentStepKey);
			if (hasLocalValuesResults(localValues)) {
				return mapStepKeyResultForMultipleAdditionalItems({
					localValues,
					custom_report_step_key_additional_item,
					values,
					stepKeyId,
					currentStepKey
				});
			}

			return mapStepKeyResultForUniqueAdditionalItem({
				additionalItem,
				custom_report_step_key_additional_item,
				values,
				stepKeyId,
				currentStepKey
			});
		});
	}

	function isEmptyStepKey(curentResult: StepKeyResultValues | SubStepKeysResult): boolean {
		return !curentResult;
	}

	function hasSubStepKeyResult(
		currentResult: StepKeyResultValues | SubStepKeysResult
	): currentResult is SubStepKeysResult {
		return !currentResult?.result;
	}

	function mapEmptyStepKeyResult(currentStepKey: StepKey): UpdateStepKeyResult {
		return {
			...defineEmptyResult(currentStepKey),
			sub_step_keys_results: currentStepKey.sub_step_keys?.map((subStepKey) => ({
				...defineEmptyResult(currentStepKey),
				custom_report_sub_step_key_id: subStepKey?.id,
				sub_step_key_result_id: subStepKey?.custom_report_sub_step_key_result?.id
			})),
			step_key_result_id: currentStepKey?.custom_report_step_key_result?.id
		};
	}

	function mapStepKeyResultForSubStepKey({
		stepKeyId,
		currentStepKey,
		subStepKeyFormValues
	}: StepKeyResultForSubStepKey) {
		const sub_step_keys_results = handleSubStepKeys(subStepKeyFormValues, currentStepKey);
		return {
			sub_step_keys_results,
			custom_report_step_key_id: stepKeyId,
			step_key_result_id: currentStepKey?.custom_report_step_key_result?.id
		};
	}

	function mapStepKeyResultForUniqueAdditionalItem({
		values,
		stepKeyId,
		additionalItem,
		currentStepKey,
		custom_report_step_key_additional_item
	}: StepKeyResultForUniqueAdditionalItem) {
		let additionalItems: UpdateOrCreateAdditionalItemResult[] = [];
		for (const additionalItemId in additionalItem) {
			const additionalItemResult = additionalItem[additionalItemId];
			if (hasResult(additionalItemResult)) {
				const mappedResult: UpdateOrCreateAdditionalItemResult[] = additionalItemResult?.results?.map(
					(result) => ({
						...result,
						custom_report_result_id: ewa?.result_id,
						custom_report_step_key_additional_item_id: custom_report_step_key_additional_item?.id
					})
				);
				additionalItems = mappedResult;
			}
		}

		return {
			...values[stepKeyId],
			additional_items: additionalItems,
			custom_report_step_key_id: stepKeyId,
			step_key_result_id: currentStepKey?.custom_report_step_key_result?.id
		};
	}

	function mapStepKeyResultForMultipleAdditionalItems({
		localValues,
		custom_report_step_key_additional_item,
		values,
		stepKeyId,
		currentStepKey
	}: StepKeyResultForMultipleAdditionalItems) {
		const mappedResult: UpdateOrCreateAdditionalItemResult[] = localValues
			.flatMap(({ results }) => results)
			.map((result) => ({
				...result,
				custom_report_result_id: ewa?.result_id,
				custom_report_step_key_additional_item_id: custom_report_step_key_additional_item?.id
			}));

		return {
			...values[stepKeyId],
			additional_items: mappedResult,
			custom_report_step_key_id: stepKeyId,
			step_key_result_id: currentStepKey?.custom_report_step_key_result?.id
		};
	}

	function handleSubStepKeys(values: SubStepKeysResult, currentStepKey: StepKey): UpdateOrCreateSubStepKeyResult[] {
		return Object.keys(values).map((subStepKeyId) => {
			const currentSubStepKey = currentStepKey.sub_step_keys?.find(({ id }) => id === subStepKeyId);
			if (!values || (!values[subStepKeyId] && currentSubStepKey)) {
				return {
					...defineEmptyResult(currentStepKey),
					custom_report_sub_step_key_id: subStepKeyId,
					step_key_result_id: currentStepKey?.custom_report_step_key_result?.id,
					sub_step_key_result_id: currentSubStepKey?.custom_report_sub_step_key_result?.id
				};
			}

			return {
				...values[subStepKeyId],
				custom_report_sub_step_key_id: subStepKeyId,
				custom_report_step_key_id: currentStepKey.id,
				step_key_result_id: currentStepKey?.custom_report_step_key_result?.id,
				sub_step_key_result_id: currentSubStepKey?.custom_report_sub_step_key_result?.id
			};
		});
	}

	function hasLocalValuesResults(
		localValues: AdditionalItem | AdditionalItem[] | undefined
	): localValues is AdditionalItem[] {
		return !!localValues && (localValues as AdditionalItem[]).length > 0;
	}

	function hasResult(
		additional_item_result: AdditionalItem | AdditionalItem[]
	): additional_item_result is AdditionalItem {
		const results = (additional_item_result as AdditionalItem)?.results;
		return !!results && results?.length > 0;
	}

	function mapStepKeyAdditionalItem(currentStepKey: StepKey | undefined): StepKeyAdditionalItem | undefined {
		return currentStepKey?.additional_items
			?.filter(({ CustomReportStepKeysAdditionalItem }) => !!CustomReportStepKeysAdditionalItem?.id)
			?.map(({ CustomReportStepKeysAdditionalItem }) => CustomReportStepKeysAdditionalItem)[0];
	}

	function defineEmptyResult(currentStepKey: StepKey): UpdateStepKeyResult | UpdateOrCreateSubStepKeyResult {
		return {
			result: undefined,
			score: undefined,
			severity_id: undefined,
			exposure_id: undefined,
			risk_damage_id: undefined,
			additional_items: undefined,
			vulnerability_id: undefined,
			risk_category_id: undefined,
			risk_description_id: undefined,
			custom_report_step_key_id: currentStepKey.id
		};
	}

	function isRebaStepKey(stepKey: StepKey) {
		return stepKey?.name === REBA_STEP;
	}

	function doesNotHaveReba() {
		return !ewa.tools.reba || !ewa.tools.reba?.data || ewa.tools.reba.data.length === 0;
	}

	const isLoading = creatingStepKeys || updatingStepKeys || updatingScores;

	return (
		<>
			<Row justify="center" gutter={[5, 20]}>
				<Col xs={21}>
					<Row align="middle">
						<Col xs={24}>
							<Title level={4}>{title}</Title>
						</Col>

						<Col xs={24} style={{ marginBottom: '20px' }}>
							<Paragraph level={5}>Enter the data related to the selected video below</Paragraph>
						</Col>
					</Row>
					<Row align="middle" gutter={[0, 25]}>
						{stepKeys?.map((stepKey) => (
							<StepKeysProvider
								key={stepKey.id}
								file_id={file_id}
								tools={ewa.tools}
								stepKey={stepKey}
								formFieldName={[stepId, stepKey.id]}
							>
								<C.CollapseCustom
									title={stepKey.description}
									disabled={isRebaStepKey(stepKey) && doesNotHaveReba()}
								>
									<C.StepKey
										subStepKeysComponent={<SubStepKeys subStepKeys={stepKey?.sub_step_keys} />}
									/>
								</C.CollapseCustom>
							</StepKeysProvider>
						))}
					</Row>
				</Col>
			</Row>
			<Row>
				<Col xs={24} style={{ marginTop: '2rem' }}>
					<Row justify="center" align="middle" gutter={[0, 8]}>
						<PreviousCol>
							<CustomButton size="large" type="default" onClick={handlePreviousStep} loading={isLoading}>
								{I18n.get('Previous')}
							</CustomButton>
						</PreviousCol>
						<Col>
							<CustomButton
								htmlType="submit"
								size="large"
								type="primary"
								onClick={handleValidation}
								loading={isLoading}
							>
								{lastGenericStepNumber === stepNumber ? I18n.get('Finish') : I18n.get('Next')}
							</CustomButton>
						</Col>
					</Row>
				</Col>
			</Row>
		</>
	);
};
