import React, { ReactNode, useState, useEffect } from 'react';
import { Row, Col, Divider, Skeleton, Form, TreeDataNode } from 'antd';
import { useHistory } from 'react-router-dom';
import { I18n } from '@aws-amplify/core';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import _ from 'lodash';

import type { ConsolidateReportDTO, CreateCommentsDTO, SubStepKeyResult, DownloadPDFDTO, StepResult } from '@/hooks';
import { StepKeysResultsProvider } from './components/Steps/StepKeyDescription/context';
import { ButtonsCustom } from '@/components/views/Report/ReportTemplate/ButtonsCustom';
import { CustomButton } from '@/components/ui/Buttons/CustomButton/styles';
import { ReportTemplate } from '@/components/views/Report/ReportTemplate';
import { useApplicationContext } from '@/context/v1/Application/context';
import { useEwaJdsD86Context } from '../../../context';
import { Title } from '@/components/Typography';
import { PreviousCol } from '../../styles';
import { useFile } from '@/hooks/v1';
import * as C from './components';
import {
	useUpdateCustomReportResultComment,
	useConsolidateCustomReport,
	useDownloadCustomReportPDF,
	useGetCustomReportResults
} from '@/hooks';
import { useGetCustomReportReviews } from '@/hooks/useGetCustomReportReviews';
import { useGetCustomReportReviewHistory } from '@/hooks/useGetCustomReportReviewHistory';
import { GeneralHistory } from './components/GeneralHistory';
import { DownloadPDFHistoryDTO, useDownloadCustomReportHistoryPDF } from '@/hooks/useDownloadCustomReportHistoryPDF';
import {
	CreateReviewCommentsDTO,
	useUpdateCustomReportResultReviewComment
} from '@/hooks/useUpdateCustomReportResultReviewComment';
import { PDFSections } from '@/types';

type Sections = {
	id: string;
	title: string;
	component: ReactNode;
};

type ToolMapper = {
	[key: string]: Sections;
};

type InitialValues = {
	[key: string]: Sections;
};

const { useFormInstance } = Form;

export const Results: React.FC = () => {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const history = useHistory();
	const [selectedReview, setSelectedReview] = useState(-1);
	const { organization, company } = useApplicationContext();
	const { handlePreviousStep, ewaJdsD86, customReport, isEwa, original_custom_report_result_id } =
		useEwaJdsD86Context();
	const form = useFormInstance();

	const initialValues = [
		PDFSections.REBA,
		PDFSections.NIOSH,
		PDFSections.KIM_PP,
		PDFSections.KIM_MHO,
		PDFSections.ACTION_PLANS,
		PDFSections.STRAIN_INDEX,
		PDFSections.NOT_EVALUATED,
		PDFSections.LIBERTY_MUTUAL,
		isEwa && PDFSections.CHARACTERISTICS,
		isEwa && PDFSections.WORK_CONDITIONS
	].filter(Boolean) as PDFSections[];

	const treeData = [
		isEwa && {
			selectable: false,
			key: 'work_conditions',
			style: { fontWeight: 'bold' },
			title: I18n.get('Working conditions')
		},
		isEwa && {
			selectable: false,
			key: 'characteristics',
			style: { fontWeight: 'bold' },
			title: I18n.get('Characteristics')
		},
		{
			selectable: false,
			key: 'not_evaluated',
			style: { fontWeight: 'bold' },
			title: I18n.get('Criteria not evaluated')
		},
		{
			title: I18n.get('Tools'),
			key: 'tools',
			selectable: false,
			style: { fontWeight: 'bold' },
			switcherIcon: <></>,
			children: [
				{
					selectable: false,
					key: 'niosh',
					title: I18n.get('Cargo Handling (NIOSH)')
				},
				{
					selectable: false,
					key: 'kim_mho',
					title: I18n.get('Manual Handling (KIM-MHO)')
				},
				{
					selectable: false,
					key: 'reba',
					title: I18n.get('Movement by score (Kinebot/JDS)')
				},
				{
					selectable: false,
					key: 'back_compressive_force_estimation',
					title: I18n.get('Back Compressive Force Estimation')
				},
				{
					selectable: false,
					key: 'liberty_mutual',
					title: I18n.get('Material Handling (Liberty Mutual)')
				}
			]
		},
		{
			selectable: false,
			key: 'action_plans',
			style: { fontWeight: 'bold' },
			title: I18n.get('Action plans')
		}
	].filter(Boolean) as TreeDataNode[];

	const { data: ewaD86Result, isLoading: resultLoading } = useGetCustomReportResults({
		company_id: company?.id,
		report_id: customReport.id,
		file_id: ewaJdsD86?.file_id,
		organization_id: organization?.id,
		custom_report_result_id: ewaJdsD86.result_id,
		original_custom_report_result_id,
		selected_pdf_sections: initialValues,
		version: Number(selectedReview) > 0 ? Number(selectedReview) : undefined
	});

	const {
		file: { data: fileInformations, isLoading: gettingFile }
	} = useFile({
		companyId: company?.id,
		fileId: ewaJdsD86.file_id,
		organizationId: organization?.id
	});

	const { data: reviewSelector } = useGetCustomReportReviews({
		company_id: company?.id,
		organization_id: organization?.id,
		original_custom_report_result_id
	});

	const { data: reviewHistory, isFetching: isFetchingHistory } = useGetCustomReportReviewHistory({
		company_id: company?.id,
		organization_id: organization?.id,
		review_id: reviewSelector.find((f) => f.version === selectedReview)?.id,
		enabled: selectedReview === 0
	});

	const versions = reviewSelector?.map((reviewOption) => {
		const regex = /\d/;
		let label = regex.test(reviewOption?.name)
			? reviewOption?.name
					?.split(' ')
					.map((m) => I18n.get(m))
					.join(' ')
			: (I18n.get(reviewOption?.name) as string);

		if (reviewOption.version > 0) {
			label = `${label} (${moment(reviewOption?.created_at).format('L')})`;
		}

		return {
			value: reviewOption?.version,
			label
		};
	});

	const { mutateAsync: downloadPDF, isLoading: downloadPDFLoading } = useDownloadCustomReportPDF();
	const { mutateAsync: downloadHistoryPDF, isLoading: downloadHistoryPDFLoading } =
		useDownloadCustomReportHistoryPDF();
	const { mutateAsync: updateComments, isLoading: commentsLoading } = useUpdateCustomReportResultComment();
	const { mutateAsync: updateReviewComments, isLoading: commentsReviewLoading } =
		useUpdateCustomReportResultReviewComment();
	const { mutateAsync: consolidateReport, isLoading: consolidateReportLoading } = useConsolidateCustomReport();

	const loading =
		resultLoading ||
		commentsLoading ||
		commentsReviewLoading ||
		downloadPDFLoading ||
		consolidateReportLoading ||
		gettingFile ||
		downloadHistoryPDFLoading;
	const browserLanguage = window.navigator.language ?? 'en-US';
	const isReportConsolidated = ewaD86Result.consolidated;

	const showVersions = versions.length > 2;
	const isLastVersionSelected = versions[versions.length - 1]?.value === selectedReview || selectedReview === -1;

	function handleNextStep(): void {
		if (!isReportConsolidated && (isLastVersionSelected || isEwa)) {
			return setIsModalOpen(true);
		}
		const url = isEwa ? '/reporting' : '/custom-reports/jds-d86';
		history.push(url);
	}

	function hasErgonomicTool(nameSection: string): boolean {
		return !!ewaD86Result.tools_to_show.find((tool) => tool === nameSection);
	}

	function hasActionPlan(nameSection: string) {
		return nameSection === 'action_plans' && !!ewaD86Result?.action_plans;
	}

	function mapSectionArray(steps: StepResult[]) {
		const initialMapper: InitialValues = isEwa
			? {
					informations: {
						id: ewaD86Result?.informations?.id,
						title: I18n.get('Results'),
						component: (
							<C.FinalResults
								worstScore={ewaD86Result?.informations?.sum_score}
								worstValues={ewaD86Result?.informations?.worst_values}
							/>
						)
					},
					work_conditions: {
						id: ewaD86Result?.work_conditions?.id,
						title: I18n.get('Working conditions'),
						component: <C.WorkConditions workConditions={ewaD86Result?.work_conditions} />
					},
					characteristics: {
						id: ewaD86Result?.characteristics?.id,
						title: I18n.get('Characteristics'),
						component: <C.Characteristics characteristics={ewaD86Result?.characteristics} />
					}
			  }
			: {
					informations: {
						id: ewaD86Result?.informations?.id,
						title: I18n.get('Results'),
						component: (
							<C.FinalResults
								worstScore={ewaD86Result?.informations?.sum_score}
								worstValues={ewaD86Result?.informations?.worst_values}
							/>
						)
					}
			  };

		const toolsMapper: ToolMapper = {
			niosh: {
				title: '',
				id: ewaD86Result?.niosh?.id,
				component: <C.NioshResults data={ewaD86Result?.niosh} />
			},
			kim_mho: {
				title: '',
				id: ewaD86Result?.kim_mho?.id,
				component: <C.KimManualHandlingResults data={ewaD86Result?.kim_mho} />
			},
			kim_pp: {
				title: '',
				id: ewaD86Result?.kim_pp?.id,
				component: <C.KimPushPullResults data={ewaD86Result?.kim_pp} />
			},
			liberty_mutual: {
				title: '',
				id: uuidv4(),
				component: <C.LibertyMutualResults data={ewaD86Result?.liberty_mutual} />
			},
			strain_index: {
				title: '',
				id: ewaD86Result?.strain_index?.id,
				component: <C.StrainIndexResults data={ewaD86Result?.strain_index} />
			},
			reba: {
				title: '',
				id: ewaD86Result?.reba?.id,
				component: <C.RebaResults reba={ewaD86Result?.reba} />
			},
			action_plans: {
				title: '',
				id: uuidv4(),
				component: <C.ActionPlans data={ewaD86Result?.action_plans} />
			}
		};

		const reportOrder = [
			'informations',
			'work_conditions',
			'characteristics',
			'niosh',
			'kim_mho',
			'kim_pp',
			'liberty_mutual',
			'strain_index',
			'reba',
			'action_plans'
		];

		const sections = [] as Sections[];

		reportOrder.forEach((nameSection: string) => {
			if (initialMapper[nameSection]) {
				sections.push(initialMapper[nameSection]);
			}
		});

		steps.forEach((step) => {
			if (step.description === 'Video selection') {
				return;
			}
			sections.push({
				id: uuidv4(),
				title: I18n.get(step.description),
				component: (
					<>
						<Divider
							type="vertical"
							style={{
								width: '4px',
								border: 'none',
								height: '20px',
								borderRadius: '2px',
								margin: '0 0 0 52px',
								backgroundColor: '#e6e8e8'
							}}
						/>
						{renderStepDescription(step)}
					</>
				)
			});
		});

		reportOrder.forEach((nameSection: string) => {
			if (toolsMapper[nameSection] && (hasActionPlan(nameSection) || hasErgonomicTool(nameSection))) {
				sections.push(toolsMapper[nameSection]);
			}
		});

		return sections;
	}

	function renderStepDescription(step: StepResult): React.ReactNode {
		return step.step_keys.map((stepKey, index, stepKeys) => {
			if (hasSubStepKeyResult(stepKey.sub_step_keys)) {
				return mapSubStepKeys(stepKey.sub_step_keys);
			}
			return (
				<StepKeysResultsProvider key={stepKey.id} stepKey={stepKey}>
					<C.StepsDescription isLastStepKey={stepKeys.length - 1 === index} />
				</StepKeysResultsProvider>
			);
		});
	}

	function mapSubStepKeys(subStepKeys: SubStepKeyResult[]): JSX.Element[] {
		return subStepKeys.map((subStepKey, index) => (
			<StepKeysResultsProvider key={subStepKey.id} stepKey={subStepKey}>
				<C.StepsDescription isLastStepKey={subStepKeys.length === index} />
			</StepKeysResultsProvider>
		));
	}

	function hasSubStepKeyResult(subStepKey: SubStepKeyResult[] | undefined): subStepKey is SubStepKeyResult[] {
		return !!subStepKey && subStepKey.length > 0;
	}

	async function updateComment(text: string): Promise<void> {
		const getReviewId = () => {
			if (!reviewSelector.length) {
				return undefined;
			}

			let index = selectedReview;

			if (selectedReview === -1) {
				index = versions.length - 1;
			}

			return reviewSelector.find((f) => f.version === index)?.id;
		};

		const custom_report_review_id = getReviewId();

		const parameters: CreateCommentsDTO = {
			custom_report_result_id: ewaJdsD86.result_id,
			organization_id: organization?.id,
			company_id: company?.id,
			comment: text
		};

		if (!custom_report_review_id) {
			await updateComments(parameters);
		} else {
			const reviewParams: CreateReviewCommentsDTO = {
				...parameters,
				custom_report_review_id: custom_report_review_id as string
			};
			await updateReviewComments(reviewParams);
		}

		// return;
	}

	async function onConsolidatePDF(): Promise<void> {
		const parameters: ConsolidateReportDTO = {
			locale: browserLanguage,
			company_id: company?.id,
			report_id: ewaJdsD86.id,
			file_id: ewaJdsD86.file_id,
			organization_id: organization?.id,
			custom_report_result_id: ewaJdsD86?.result_id,
			selected_pdf_sections: initialValues
		};
		await consolidateReport(parameters);
	}

	async function handleDownloadPDF(file: any): Promise<void> {
		if (selectedReview === 0) {
			const parameters: DownloadPDFHistoryDTO = {
				company_id: company?.id,
				locale: browserLanguage,
				organization_id: organization?.id,
				review_id: reviewSelector.find((f) => f.version === selectedReview)?.id as string
			};
			await downloadHistoryPDF(parameters);
			return;
		}
		const parameters: DownloadPDFDTO = {
			report_id: ewaJdsD86.id,
			company_id: company?.id,
			locale: browserLanguage,
			file_id: ewaJdsD86.file_id,
			organization_id: organization?.id,
			selected_pdf_sections: file.pdf_custom,
			custom_report_result_id: ewaJdsD86?.result_id
		};
		await downloadPDF(parameters);
	}

	async function handleChangeComment(event: React.ChangeEvent<HTMLTextAreaElement>) {
		setComment(event.target.value);
	}

	function getVersionName() {
		if (!selectedReview || !showVersions) {
			return undefined;
		}
		let index = selectedReview;
		if (isLastVersionSelected) {
			index = versions.length - 1;
		}
		return versions[index]?.label;
	}

	const setComment = _.debounce(async (value) => {
		await updateComment(value);
	}, 1200);

	const comment = reviewHistory?.reviews_history ? reviewHistory?.comment : ewaD86Result?.comment;

	useEffect(() => {
		form.setFieldValue('comment', comment);
	}, [comment]);

	const loadingHistoryReview = selectedReview === 0 && isFetchingHistory;

	if (resultLoading || gettingFile || loadingHistoryReview) {
		return <Skeleton active />;
	}

	const sections = mapSectionArray(ewaD86Result?.steps);

	return (
		<Row justify="center">
			<Col lg={21} xl={22} xxl={22}>
				<Row justify="space-between">
					<Col>
						<Title level={4} style={{ marginBottom: '2.5rem' }}>
							Result
						</Title>
					</Col>
					<Col>
						<ButtonsCustom
							component={
								(showVersions && <C.ReviewSelect onChange={setSelectedReview} options={versions} />) ||
								undefined
							}
							pdfProps={{
								initialValues,
								treeData
							}}
							fileData={fileInformations}
							hasCustomModal={isEwa || selectedReview !== 0}
							hasConsolidated={isEwa || !showVersions}
							isLoadingPDF={loading}
							onDownloadPDF={handleDownloadPDF}
							onConsolidatePDF={onConsolidatePDF}
							consolidated={isReportConsolidated}
						/>
					</Col>
				</Row>
				<ReportTemplate
					component={(reviewHistory?.informations && <GeneralHistory {...reviewHistory} />) || null}
					sections={sections}
					hasCustomModal={isEwa || selectedReview !== 0}
					hasConsolidated={isEwa || !showVersions || isLastVersionSelected}
					isLoadingPDF={loading}
					isLoading={gettingFile}
					fileData={fileInformations}
					onDownloadPDF={handleDownloadPDF}
					sector={fileInformations?.sector}
					onConsolidatePDF={onConsolidatePDF}
					onChangeComment={handleChangeComment}
					consolidated={isReportConsolidated}
					workstation={fileInformations?.workstation}
					pdfProps={{
						initialValues,
						treeData
					}}
					reportData={{ jds_d86_report: { ...ewaD86Result.informations, version: getVersionName() } }}
					title={
						isEwa
							? I18n.get('Preliminary Ergonomic Analysis') + ' | NR-17 + JDS-D86'
							: I18n.get('Ergonomic evaluation') + ' JDS-D86'
					}
				/>
			</Col>
			<Col span={24}>
				<Row>
					<Col xs={24} style={{ marginTop: '2rem' }}>
						<Row justify="center" align="middle" gutter={[0, 8]}>
							{!isReportConsolidated && (
								<PreviousCol>
									<CustomButton
										size="large"
										type="default"
										disabled={loading}
										onClick={handlePreviousStep}
									>
										{I18n.get('Previous')}
									</CustomButton>
								</PreviousCol>
							)}
							<Col>
								<CustomButton
									size="large"
									type="primary"
									htmlType="submit"
									loading={loading}
									disabled={loading}
									onClick={handleNextStep}
								>
									{I18n.get('Close')}
								</CustomButton>
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
			<C.ConsolidatedModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
		</Row>
	);
};
